import Form from '../components/Form';

function Add() {
  return (
    <div className="min-h-screen w-full bg-gray-100">
      <div className="mx-auto max-w-6xl">
        <div className="py-16">
          <Form
            initData={{
              nama: '',
              paket: [],
              color: '',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Add;
