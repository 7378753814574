import axios from '../config/axios';
import { useEffect, useRef, useState } from 'react';
import ModalPaket from './ModalPaket';
import { useNavigate } from 'react-router-dom';

function Form({ id, initData }) {
  const [data, setData] = useState(null);
  const [paketIndex, setPaketIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const modalPaketRef = useRef();

  let navigate = useNavigate();

  useEffect(() => {
    // console.log('initData', initData);
    setData(initData);
  }, [initData]);

  const openModalPaket = (i = null, d = null) => {
    setPaketIndex(i);
    modalPaketRef.current.openModal(d);
  };

  const deletePaket = (i) => {
    let mData = { ...data };
    if (i > -1) {
      mData.paket.splice(i, 1);
    }
    setData(mData);
  };

  const handleOnChangeField = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmitModal = (d) => {
    console.log('paket', d);
    console.log('paket index', paketIndex);
    if (paketIndex > -1) {
      console.log('if');
      let mData = { ...data };
      mData.paket[paketIndex] = d;
      setData(mData);
    } else {
      console.log('else');
      data.paket.length > 0
        ? setData({
            ...d,
            paket: [...data.paket, d],
          })
        : setData({
            ...d,
            paket: [d],
          });
    }
  };

  const handleOnsubmit = async (e) => {
    e.preventDefault();
    if (id) {
      updateData();
    } else {
      saveData();
    }
  };

  const saveData = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`/api/progress-infrastruktur`, data);
      console.log('res', res);
      navigate(-1);
    } catch (error) {
      console.error(error.response);
    } finally {
      setLoading(false);
    }
  };

  const updateData = async () => {
    try {
      setLoading(true);
      const res = await axios.put(`/api/progress-infrastruktur/${id}`, data);
      console.log('res', res);
      navigate(-1);
    } catch (error) {
      console.error(error.response);
    } finally {
      setLoading(false);
    }
  };

  if (!data) {
    return <div className="mx-auto">Please wait...</div>;
  }

  return (
    <>
      <form
        className="overflow-hidden bg-white shadow sm:rounded-lg"
        onSubmit={(e) => handleOnsubmit(e)}
      >
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Form Progress Infrastruktur
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details and application.
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Nama Infrastruktur
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <input
                      type="text"
                      name="nama"
                      id="nama"
                      autoComplete="nama"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      defaultValue={data.nama}
                      onChange={(e) => handleOnChangeField(e)}
                    />
                  </div>
                </div>
              </dd>
              <dt className="text-sm font-medium text-gray-500">
                Background Color
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    {/* <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                        Country
                      </label> */}
                    <select
                      id="color"
                      name="color"
                      autoComplete="ccolor"
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={data.color}
                      onChange={(e) => handleOnChangeField(e)}
                    >
                      <option value="sky">Sky</option>
                      <option value="blue">Blue</option>
                      <option value="teal">Teal</option>
                      <option value="purple">Purple</option>
                      <option value="yellow">Yellow</option>
                      <option value="orange">Orange</option>
                      <option value="rose">Rose</option>
                      {/* <option value="yellow">Yellow</option> */}
                    </select>
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Paket</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {/* <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <input
                    type="text"
                    name="nama"
                    id="nama"
                    autoComplete="nama"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div> */}

                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={() => openModalPaket(-1, null)}
                >
                  Tambah Paket
                </button>

                <div className="mt-4 overflow-hidden rounded-md border">
                  <table className="w-full table-auto">
                    <thead>
                      <tr className="bg-gray-50 ">
                        <th className="px-4 py-2 text-left">Nama</th>
                        <th className="px-4 py-2 text-left">Rencana</th>
                        <th className="px-4 py-2 text-left">Realisasi</th>
                        <th className="px-4 py-2 text-left">Deviasi</th>
                        <th className="px-4 py-2 text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.paket.map((d, i) => (
                          <tr key={i}>
                            <td className="px-4 py-2">{d.nama}</td>
                            <td className="px-4 py-2">{d.rencana}</td>
                            <td className="px-4 py-2">{d.realisasi}</td>
                            <td className="px-4 py-2">{d.deviasi}</td>
                            <td className="flex px-4 py-2">
                              <button
                                type="button"
                                className="mr-4 text-sm font-medium text-blue-500"
                                onClick={() => openModalPaket(i, d)}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="text-sm font-medium text-red-500"
                                onClick={() => deletePaket(i, d)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </dd>
            </div>
          </dl>
        </div>
        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {loading && (
              <svg
                className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            Simpan
          </button>
        </div>
      </form>
      <ModalPaket
        ref={modalPaketRef}
        onSubmit={(d) => handleOnSubmitModal(d)}
      />
    </>
  );
}

export default Form;
