import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Add from './pages/Add';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import Update from './pages/Update';
// import logo from './logo.svg';
// import './App.css';

function App() {
  return (
    <div className="bg-gray-100">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/add" element={<Add />} />
        <Route path="/update/:id" element={<Update />} />
      </Routes>
    </div>
  );
}

export default App;
