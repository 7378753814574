/* This example requires Tailwind CSS v2.0+ */
import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';

function ModalPaket(props, ref) {
  const [open, setOpen] = useState(false);
  const [paket, setPaket] = useState({
    nama: '',
    rencana: '',
    realisasi: '',
    deviasi: '',
  });

  const cancelButtonRef = useRef(null);

  const openModal = (d) => {
    setOpen(true);
    if (d) {
      setPaket(d);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      openModal: openModal,
    };
  });

  useEffect(() => {
    console.log(paket);
  }, [paket]);

  const handleOnChangeField = (e) => {
    setPaket({
      ...paket,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnsubmit = (e) => {
    e.preventDefault();
    props.onSubmit(paket);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            onClick={() => setOpen(false)}
          />
        </Transition.Child>

        <form
          className="fixed inset-0 z-10 overflow-y-auto"
          onSubmit={(e) => handleOnsubmit(e)}
        >
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-blue-800"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Form Paket
                      </Dialog.Title>
                      <div className="mt-2 w-full">
                        {/* <p className="text-sm text-gray-500">
                          Are you sure you want to deactivate your account? All
                          of your data will be permanently removed. This action
                          cannot be undone.
                        </p> */}
                        <div className="mt-8 w-full">
                          <div className="mt-4 grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-6">
                              <label
                                htmlFor="nama"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Nama Paket
                              </label>
                              <input
                                type="text"
                                name="nama"
                                id="nama"
                                autoComplete="nama"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                defaultValue={paket.nama}
                                onChange={(e) => handleOnChangeField(e)}
                              />
                            </div>
                          </div>
                          <div className="mt-4 grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-6">
                              <label
                                htmlFor="rencana"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Rencana
                              </label>
                              <input
                                type="text"
                                name="rencana"
                                id="rencana"
                                autoComplete="rencana"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                defaultValue={paket.rencana}
                                onChange={(e) => handleOnChangeField(e)}
                              />
                            </div>
                          </div>
                          <div className="mt-4 grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-6">
                              <label
                                htmlFor="realisasi"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Realisasi
                              </label>
                              <input
                                type="text"
                                name="realisasi"
                                id="realisasi"
                                autoComplete="realisasi"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                defaultValue={paket.realisasi}
                                onChange={(e) => handleOnChangeField(e)}
                              />
                            </div>
                          </div>
                          <div className="mt-4 grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-6">
                              <label
                                htmlFor="deviasi"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Deviasi
                              </label>
                              <input
                                type="text"
                                name="deviasi"
                                id="deviasi"
                                autoComplete="deviasi"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                defaultValue={paket.deviasi}
                                onChange={(e) => handleOnChangeField(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Simpan
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
}

export default forwardRef(ModalPaket);
