import axios from '../config/axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//dashboard page

function Dashboard() {
  const [infras, setInfras] = useState([]);
  const [infrasDate, setInfrasDate] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchInfras();
    fetchInfrasDate();
  }, []);

  const fetchInfras = async () => {
    console.log('fetch progress');
    try {
      const res = await axios.get('/api/progress-infrastruktur');
      console.log('progress', res);
      setInfras(res.data.data.progress_infrastruktur);
    } catch (error) {
      console.error(error.response);
    }
  };

  const fetchInfrasDate = async () => {
    try {
      const res = await axios.get('/api/progress-infrastruktur/date/first');
      setInfrasDate(res.data.data.progress);
    } catch (error) {
      console.error(error.response);
    }
  };

  const saveInfrasDate = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.put(
        `/api/progress-infrastruktur/date/${infrasDate.id}`,
        infrasDate
      );
      console.log('progress', res);
    } catch (error) {
      console.error(error.response);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full bg-gray-100">
      <div className="mx-auto max-w-6xl">
        <div className="py-16">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="flex items-center justify-between px-4 py-5 sm:px-6">
              <div>
                <h3 className="text-xl font-medium leading-6 text-gray-900">
                  Tanggal Progress Infrastruktur
                </h3>
                {/* <p className="mt-1 max-w-2xl text-sm text-gray-500"></p> */}
              </div>
              {/* <div>
                <Link
                  to="/add"
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Tambah Data
                </Link>
              </div> */}
            </div>
            <div className="border-t border-gray-200">
              <form
                className="flex items-center justify-between p-6"
                onSubmit={(e) => saveInfrasDate(e)}
              >
                <div className="mr-6">
                  {/* <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label> */}
                  <input
                    type="date"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className=" block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    defaultValue={infrasDate && infrasDate.tanggal}
                    onChange={(e) =>
                      setInfrasDate({
                        ...infrasDate,
                        tanggal: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    {loading && (
                      <svg
                        className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-8 bg-white shadow sm:rounded-lg">
            <div className="flex items-center justify-between px-4 py-5 sm:px-6">
              <div>
                <h3 className="text-xl font-medium leading-6 text-gray-900">
                  Daftar Progress Infrastruktur
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Tabel Informasi Progress Infrastruktur
                </p>
              </div>
              <div>
                <Link
                  to="/add"
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Tambah Data
                </Link>
              </div>
            </div>
            <div className="border-t border-gray-200">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full">
                  <thead className="bg-gray-50 text-sm font-semibold text-gray-500">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left">
                        Nama
                      </th>
                      <th scope="col" className="px-6 py-3 text-right">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {infras &&
                      infras.map((d, i) => (
                        <tr key={i} className="border-t">
                          <td className="px-6 py-4 font-medium uppercase">
                            {d.nama}
                          </td>
                          <td className="px-6 py-4 text-right text-sm font-medium">
                            <Link
                              to={`/update/${d.id}`}
                              className="text-blue-500"
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
