import axios from '../config/axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormGenerator from '../components/FormGenerator';
import Form from '../components/Form';

function Update() {
  let { id } = useParams();
  const [infras, setInfras] = useState();

  useEffect(() => {
    console.log('id', id);
    fetchDetail();
  }, []);

  const fetchDetail = async () => {
    try {
      const res = await axios.get(`/api/progress-infrastruktur/${id}`);
      console.log('infras', res.data.data.progress_infrastruktur);
      setInfras(res.data.data.progress_infrastruktur);
    } catch (error) {
      console.error(error.response);
    }
  };

  return (
    <div className="min-h-screen w-full bg-gray-100">
      <div className="mx-auto max-w-6xl">
        <div className="py-16">
          {/* <FormGenerator
            model={{
              nama: {
                name: 'Nama Infrastruktur',
                type: 'text',
                default: '',
              },
              paket: {
                name: 'Paket',
                type: 'array',
                model: {
                  progress_infrastruktur_id: {
                    type: 'hidden',
                    default: 0,
                  },
                  nama: {
                    name: 'Nama Paket',
                    type: 'text',
                    default: '',
                  },
                  rencana: {
                    name: 'Rencana',
                    type: 'text',
                    default: '',
                  },
                  realisasi: {
                    name: 'Realisasi',
                    type: 'text',
                    default: '',
                  },
                  deviasi: {
                    name: 'Deviasi',
                    type: 'text',
                    default: '',
                  },
                },
                default: [],
              },
            }}
            initData={infras}
          /> */}
          <Form id={id} initData={infras} />
        </div>
      </div>
    </div>
  );
}

export default Update;
