function InfoCard({ name, paket, bgcolor }) {
  return (
    <div className="relative mt-8 w-full">
      <div className="absolute z-10 h-full w-full">
        <div className="flex h-full w-full flex-col">
          <div className="h-20"></div>
          <div
            className={`h-full w-full flex-1 bg-gradient-to-b p-4 from-${bgcolor}-400 to-${bgcolor}-500 rounded-2xl shadow-lg`}
          ></div>
        </div>
      </div>
      <div className="relative z-20 h-full p-4">
        <div className="flex h-full flex-col justify-between">
          <div className="relative h-full w-full rounded-2xl bg-white shadow-lg">
            <div className="absolute -top-8 z-20 w-full">
              <div className="flex justify-center">
                <div
                  className={`bg-gradient-to-b from-${bgcolor}-400 to-${bgcolor}-500 inline-block rounded-full p-4 text-white shadow-md`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="bi bi-bar-chart-line-fill h-6 w-6"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="relative z-20 p-8 md:p-4">
              {paket &&
                paket.map((d, i) => (
                  <div key={i} className="mt-4 flex flex-col items-center">
                    <div
                      className={`text-${bgcolor}-800 text-center text-lg font-semibold`}
                    >
                      {d.nama}
                    </div>
                    <table className="mt-2">
                      <tbody>
                        <tr>
                          <td>
                            <div
                              className={`uppercase text-${bgcolor}-900 px-4 text-[12px]`}
                            >
                              Rencana
                            </div>
                          </td>
                          <td>
                            <div
                              className={`uppercase text-${bgcolor}-900 px-4 text-[12px]`}
                            >
                              {d.rencana} %
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className={`uppercase text-${bgcolor}-900 px-4 text-[12px]`}
                            >
                              Realisasi
                            </div>
                          </td>
                          <td>
                            <div
                              className={`uppercase text-${bgcolor}-900 px-4 text-[12px]`}
                            >
                              {d.realisasi} %
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className={`uppercase text-${bgcolor}-900 px-4 text-[12px]`}
                            >
                              Deviasi
                            </div>
                          </td>
                          <td>
                            <div
                              className={`uppercase text-${bgcolor}-900 px-4 text-[12px]`}
                            >
                              {d.deviasi} %
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
            </div>

            <div className="absolute top-0 z-10 h-full w-full rounded-2xl bg-white"></div>

            <div className="absolute -bottom-4 z-10 w-full">
              <div className="flex justify-center">
                <div className="h-20 w-20 rotate-45 rounded-md bg-white"></div>
              </div>
            </div>
            <div className="absolute -bottom-4 z-0 w-full">
              <div className="flex justify-center">
                <div className="h-20 w-20 rotate-45 rounded-md bg-white shadow-lg"></div>
              </div>
            </div>
          </div>
          <div className="mt-16 text-center text-2xl font-bold uppercase text-white md:text-lg">
            {name}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCard;
